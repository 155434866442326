import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { LightboxModule } from 'ngx-lightbox';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CountUpModule } from 'ngx-countup';
import { NgxMasonryModule } from 'ngx-masonry';

import { SimplebarAngularModule } from 'simplebar-angular';


import { SharedModule } from "./shared/shared.module";

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';


import { IndexSingleProductComponent } from './core/components/index-single-product/index-single-product.component';

import { WidgetComponent } from './core/components/widget/widget.component';
import { FeatherModule } from 'angular-feather';

// Apex chart
import { NgApexchartsModule } from 'ng-apexcharts';

import { allIcons } from 'angular-feather/icons';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { UiButtonComponent } from './core/components/ui-button/ui-button.component';
import { UiAlertComponent } from './core/components/ui-alert/ui-alert.component';
import { UiTypographyComponent } from './core/components/ui-typography/ui-typography.component';
import { UiDropdownComponent } from './core/components/ui-dropdown/ui-dropdown.component';
import { UiBackgroundComponent } from './core/components/ui-background/ui-background.component';
import { UiTextComponent } from './core/components/ui-text/ui-text.component';
import { UiBadgesComponent } from './core/components/ui-badges/ui-badges.component';
import { UiAccordionComponent } from './core/components/ui-accordion/ui-accordion.component';
import { UiCardComponent } from './core/components/ui-card/ui-card.component';
import { UiShadowComponent } from './core/components/ui-shadow/ui-shadow.component';
import { UiBorderComponent } from './core/components/ui-border/ui-border.component';
import { UiCarouselComponent } from './core/components/ui-carousel/ui-carousel.component';
import { UiFormComponent } from './core/components/ui-form/ui-form.component';
import { UiBreadcrumbComponent } from './core/components/ui-breadcrumb/ui-breadcrumb.component';
import { UiPaginationComponent } from './core/components/ui-pagination/ui-pagination.component';
import { UiAvatarComponent } from './core/components/ui-avatar/ui-avatar.component';
import { UiNavTabsComponent } from './core/components/ui-nav-tabs/ui-nav-tabs.component';
import { UiModalsComponent } from './core/components/ui-modals/ui-modals.component';
import { UiTablesComponent } from './core/components/ui-tables/ui-tables.component';
import { UiIconsComponent } from './core/components/ui-icons/ui-icons.component';
import { UiProgressbarComponent } from './core/components/ui-progressbar/ui-progressbar.component';
import { UiLightboxComponent } from './core/components/ui-lightbox/ui-lightbox.component';
import { UiTooltipPopoverComponent } from './core/components/ui-tooltip-popover/ui-tooltip-popover.component';



@NgModule({
  declarations: [
    AppComponent,
    
    IndexSingleProductComponent,
    
    WidgetComponent,
   
    UiButtonComponent,
    UiAlertComponent,
    UiTypographyComponent,
    UiDropdownComponent,
    UiBackgroundComponent,
    UiTextComponent,
    UiBadgesComponent,
    UiAccordionComponent,
    UiCardComponent,
    UiShadowComponent,
    UiBorderComponent,
    UiCarouselComponent,
    UiFormComponent,
    UiBreadcrumbComponent,
    UiPaginationComponent,
    UiAvatarComponent,
    UiNavTabsComponent,
    UiModalsComponent,
    UiTablesComponent,
    UiIconsComponent,
    UiProgressbarComponent,
    UiLightboxComponent,
    UiTooltipPopoverComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    CarouselModule,
    FeatherModule.pick(allIcons),
    ScrollToModule.forRoot(),
    RouterModule.forRoot([], {}),
    YouTubePlayerModule,
    NgbDropdownModule,
    CKEditorModule,
    NgbModule,
    NgbNavModule,
    FormsModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    NgApexchartsModule,
    NgxTypedJsModule,
    FlatpickrModule.forRoot(),
    CountUpModule,
    NgxMasonryModule,
    LightboxModule,
    SharedModule,
    SimplebarAngularModule
  ],
  exports: [
    FeatherModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
