<!-- Hero Start -->
<section class="bg-home d-flex align-items-center"
  style="background: center center; height: auto;" id="home">
  <div class="bg-overlay bg-overlay-white"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-9 text-center mt-0 mt-md-5 pt-0 pt-md-5">
       

        <div class="home-dashboard">
          <img src="assets/images/cakes/logo-main.png" alt="" class="img-fluid mover">
        </div> 
        
        <div class="title-heading margin-top-150">
          <h2 class="display-4 fw-bold mb-3 font-mafer">Custom Cakes and Candy Bars</h2>
          <p class="para-desc mx-auto text-muted"></p>
        </div>
      </div>
    <!-- Actions -->

 <!--end col-->
       <div class="col-md-9 text-center ">
  <a href="https://www.instagram.com/cakesbymafer/" class="outline-btn h3 font-mafer"> 
    <i class="uil uil-instagram"></i>Galeria
 </a>
  <a></a>
  <a href="https://wa.me/50762798844" class="outline-btn h3 font-mafer">  
    <i class="uil uil-whatsapp"></i>Pedidos
   </a>
</div> 
</div>
 
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

