import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexSingleProductComponent } from './core/components/index-single-product/index-single-product.component';

const routes: Routes = [
  {
    path: '',
    component: IndexSingleProductComponent
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      scrollOffset: [0, 0],
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}